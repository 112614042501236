/** @jsx jsx */
import Layout, { Row } from "components/layout"
import SEO from "components/seo"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import { Box, Container, Flex, jsx } from "theme-ui"
import Img from "gatsby-image/withIEPolyfill"
import { FileIcon } from "styles/svg"

const DownloadAsset = data => (
  <Box
    key={data.id}
    sx={{
      boxSizing: "border-box",
      px: [5, 5, 3, 5],
      mb: [5, 5, 3, 5],
      flex: ["0 0 100%", "0 0 50%", "0 0 33.3333%", "0 0 25%"],
    }}
  >
    <Link
      to={data.fileToDownload.url}
      target="_blank"
      rel="noreferrer"
      download={data.fileToDownload.filename}
    >
      {data.previewImage && !/\/svg/.test(data.previewImage.mimeType) ? (
        <Img
          alt={data.previewImage.alt}
          title={data.previewImage.title}
          fluid={data.previewImage.fluid}
        />
      ) : (
        <Flex
          sx={{
            alignItems: "center",
            overflowWrap: "anywhere",
            height: "100%",
          }}
        >
          <FileIcon
            sx={{
              flex: 0,
              fill: "text",
              width: "100%",
              minWidth: "55px",
              mr: 4,
            }}
            fileType={
              /\/svg/.test(data.fileToDownload?.mimeType)
                ? "svg"
                : data.fileToDownload.mimeType.split("/").pop()
            }
          />
          {data.fileToDownload.filename}
        </Flex>
      )}
    </Link>
  </Box>
)

const CorporateGuidelinesPage = ({ data }) => {
  const {
    title,
    body,
    seoMetaTags,
    logotypeSpeedies,
    logotypeInnovativPlast,
    imagesVideos,
  } = data.datoCmsCorporateGuideline

  const seoTitle = seoMetaTags.tags.find(
    t => !!t.tagName && /title/.test(t.tagName)
  )

  return (
    <Layout>
      <SEO datoMetaTags={seoMetaTags}>
        <link
          rel={"alternate"}
          href={"https://www.speeedies.eu/de/corporate-guidelines"}
          hrefLang={"de"}
        />
        <link
          rel={"alternate"}
          href={"https://www.speeedies.eu/fr/corporate-guidelines"}
          hrefLang={"fr"}
        />
        <meta property="og:site name" content={seoTitle.content} />
      </SEO>
      <Flex as={"section"} sx={{ pt: 10 }}>
        <Container>
          <h1 sx={{ mb: 8 }}>{title}</h1>
          <h3>Logotype Innovativ Plast</h3>
          <Row>{logotypeInnovativPlast.map(DownloadAsset)}</Row>
          <h3>Images & Videos</h3>
          <Row>{imagesVideos.map(DownloadAsset)}</Row>
          <ReactMarkdown
            sx={{
              "h1,h2,h3,h4,h5": { mt: 7 },
              ol: { listStyleType: "lower-alpha", li: { pl: 2 } },
              mt: 7,
              mb: 10,
            }}
          >
            {body}
          </ReactMarkdown>
        </Container>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CorporateGuidelines($language: String!) {
    datoCmsCorporateGuideline(locale: { eq: $language }) {
      title
      body
      logotypeInnovativPlast {
        id
        fileToDownload {
          url
          mimeType
          filename
        }
        previewImage {
          alt
          title
          mimeType
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      imagesVideos {
        id
        fileToDownload {
          url
          mimeType
          filename
        }
        previewImage {
          alt
          title
          mimeType
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      seoMetaTags {
        id
        tags
      }
    }
  }
`

export default CorporateGuidelinesPage
